import { SignIn } from "@clerk/remix";
const LoginPage = () => {
	return (
		<div>
			<div className="w-full flex justify-center py-28">
				<SignIn
					appearance={{
						elements: {
							footer: { display: "none" },
							formButtonPrimary: {
								backgroundColor: "#4F46E5",
								"&:hover": {
									backgroundColor: "#4338CA",
								},
							},
						},
					}}
				/>
			</div>
		</div>
	);
};

export default LoginPage;
